<template>
  <v-app-bar :clipped-left="true" :color="headerColor" app>
    <v-app-bar-nav-icon @click.stop="active=!active" aria-label="Show or Hide Navigation Menu"></v-app-bar-nav-icon>
    <img id="logo" src="@/assets/shield.svg" alt="Covenant Logo" style="height:40px;margin-right:1em;" />
    <v-toolbar-title class="headline">Scots Portal</v-toolbar-title>
    <span v-if="subtitle !== ''" class="ml-4" style="transform: translateY(3px)">{{ subtitle }}</span>
    <v-spacer></v-spacer>
    <v-menu bottom left v-if="isLoggedIn">
      <template v-slot:activator="{ on }">
        <v-btn v-if="spoof" v-on="on" color="warning">
          <v-icon left>fas fa-user-circle</v-icon>
          Spoofing: {{ spoof.username }}
        </v-btn>
        <v-btn v-else icon v-on="on" aria-label="User Menu">
          <v-icon>fas fa-user-circle</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-title>Logged in as: {{ username }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="canSpoof && !spoof" to="/system/spoof">
          <v-list-item-title>Spoof</v-list-item-title>
          <v-list-item-action>
            <v-icon>fal fa-user-secret</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item to="/settings">
          <v-list-item-title>Settings</v-list-item-title>
          <v-list-item-action>
            <v-icon>fal fa-cog</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-if="spoof" @click="endSpoof">
          <v-list-item-title>End Spoof</v-list-item-title>
          <v-list-item-action>
            <v-icon>fal fa-user-times</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-title>Logout</v-list-item-title>
          <v-list-item-action>
            <v-icon>fal fa-sign-out-alt</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn v-else-if="boardAccessToken" @click="boardAccessToken = null">
      <v-icon left>fal fa-sign-out</v-icon>
      Log Out
    </v-btn>
    <v-btn v-else @click="setRedirectUrl">
      <v-icon left>fal fa-user-circle</v-icon>
      <span>Log In</span>
    </v-btn>
    <v-snackbar v-model="showTimer" top right :timeout="-1">
      Auto-lock in: {{ timer }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="refreshTimout" v-bind="attrs">Refresh</v-btn>
      </template>
    </v-snackbar>
  </v-app-bar>
</template>
<style>
/* #timeout {
  position: absolute;
  top: 75px;
  right: 5px;
} */
/* header > div.v-toolbar__content > div.v-snack.v-snack--right.v-snack--top {
  transform: translateY(60px);
} */
</style>
<script>
import { ref, computed } from '@vue/composition-api'
import { setInterval } from 'timers'

export default {
  setup (props, { root }) {
    const interval = ref(null)
    const timer = ref('')
    const showTimer = ref(false)
    const username = computed(() => root.$store.state.user.username || '')
    const headerColor = computed(() => root.$vuetify.theme.dark ? '#193264' : '')
    const isLoggedIn = computed(() => root.$store.state.loggedIn || false)

    const canSpoof = computed(() => root.$store.getters.hasRole(['Technology Services', 'Financial Aid']))

    const spoof = computed(() => root.$store.state.user.spoof || false)

    const endSpoof = () => {
      // console.log(spoof.value)
      root.$feathers.service('system/spoof').remove(spoof.value._id).then((user) => {
        root.$store.commit('setUser', user)
        root.$store.commit('setRoles', user.roles)
        root.$router.push('/system/spoof')
      })
    }

    const active = computed({
      get: () => root.$store.state.sideNavActive,
      set: (val) => root.$store.commit('setSideNavActive', val)
    })

    const boardAccessToken = computed({
      get: () => root.$store.state.board.accessToken,
      set: (val) => root.$store.commit('board/setAccessToken', val)
    })

    function setRedirectUrl () {
      root.$store.commit('setLoginRedirectUrl', root.$route.path)
      root.$router.push('/login')
    }

    const subtitle = computed(() => root.$store.state.main.pageSubTitle)

    return {
      interval,
      timer,
      showTimer,
      username,
      headerColor,
      isLoggedIn,
      canSpoof,
      spoof,
      endSpoof,
      active,
      boardAccessToken,
      setRedirectUrl,
      subtitle
    }
  },
  methods: {
    updateTimer () {
      const time = new Date().getTime()
      const timeDiff = (parseInt(this.$store.state.lastActivity) + 900200 - time) / 1000
      if (timeDiff < 0) {
        this.logout(true)
        return
      }
      const mins = Math.floor(timeDiff / 60)
      const secs = Math.floor(timeDiff % 60)
      this.timer = mins + ':' + (secs < 10 ? '0' : '') + secs

      if (this.timer <= 0) {
        this.showTimer = false
        this.logout()
      } else {
        this.showTimer = mins < 5
      }
    },
    logout (auto) {
      const token = window.localStorage.getItem('feathers-jwt')
      var base64Url = token.split('.')[1]
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))

      const { nameId, sessionIndex } = JSON.parse(jsonPayload)

      this.$feathers.logout().then(() => {
        this.$store.dispatch('logout')
        // auto is set if the user was logged out due to inactivity. We only want to sing out of Microsoft if they clicked logout.
        if (!auto && nameId && sessionIndex) {
          const basePath = process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH.replace('/socket.io', '')
          window.location = basePath + `/saml/logout?nameId=${nameId}&sessionIndex=${sessionIndex}`
        }
      })
    },
    refreshTimout () {
      this.$store.commit('updateActivity')
      this.showTimer = false
    }
  },
  mounted () {
    this.interval = setInterval(this.updateTimer, 1000)
  }
}
</script>
